export const platforms = [
	{
		name: "Delete Pixel Configurations",
		brand: "Clear Pixel",
		config: "clear",
		icon: "",
		info: "Select the pixel configurations you would like to remove.",
		error: "Nothing configured. Please save a pixel configuration to continue.",
		action: "clear",
		form: {
			checkboxes: {
				google: "Google",
				facebook: "Facebook",
				tiktok: "TikTok",
				snapchat: "Snapchat",
				pinterest: "Pinterest",
				taboola: "Taboola",
				outbrain: "Outbrain",
				yahoo: "YahooGemini",
				revcontent: "Revcontent",
				mgid: "MGID",
			}
		}
	},
	{
		name: "Google Adwords",
		brand: "Google",
		config: "google",
		icon: "Google_Logo.svg",
		info: "Enter your pixel information below to setup tracking with enhanced conversions! Make sure you enable <a href='https://i.gyazo.com/e1ecd59153a24dba0a76f0631cecb3e1.png' target='_blank'> enhanced conversions </a> on your pixel in Ads Manager to take advantage of this feature.",
		info2: "The Google Adwords pixel contains two fields, the Adwords ID field and a Conversion ID, in this format: <span class='no-break'>AW-1234567890/aB2cbb1CAbbbAc3aaaB.</span>",
		action: "update",
		form: {
			input1: {
				field: "awId",
				label: "Google Adwords Pixel",
				placeholder: "AW-000000000",
				error: "Adwords ID may contain only letters and numbers.",
				info: "",
				validator: "no-space, alpha-numeric-delimited"
			},
			input2: {
				field: "conversionId",
				label: "Conversion ID",
				placeholder: "Conversion ID",
				error: "Conversion ID should contain only letters and numbers.",
				info: "",
				validator: "no-space"
			}
		},
		message: {
			heading: "",
			copy: "<i class='fa fa-exclamation-circle' style='color: #777; margin-right: 3px;'></i> Be sure you delete any existing Google pixels from Everflow Postbacks.",
		},
		resources: {
			heading: "Copy/paste the script below onto your presell pages to ensure GCLID & WBRAID passes through to GiddyUp.",
			link: '<script type="text/javascript" src="https://js.giddyup.io/gulinkfixup.js"></script>',
			copy: "Note: this code should work on most of your hosted pages but GiddyUp cannot provide support if it doesn’t work – this is something a 3rd party developer will need to assist with."
		}
	},
	{
		name: "Facebook Pixel",
		brand: "Facebook",
		config: "facebook",
		icon: "Facebook_Logo.svg",
		info: "Please enter all of the information below to easily configure client & server side pixel firing on your exclusive domain.",
		action: "update",
		form: {
			input1: {
				field: "pixelId",
				label: "Pixel ID*",
				placeholder: "Pixel ID",
				error: "This field should contain only letters and numbers.",
				info: "Insert the Pixel ID from inside your Facebook Ad Manager.",
				example: "https://gyazo.com/6b091cd78933d072bd8fbbffce0243c8",
				validator: "no-space, alpha-numeric"
			},
			input2: {
				field: "accessToken",
				label: "Access Token*",
				placeholder: "Access Token",
				error: "This field should contain only letters and numbers.",
				info: "Insert the Access Token from inside your Facebook Ad Manager.",
				example: "https://gyazo.com/c6d082ef540d5cfdebc9aa06bbb057d8",
				validator: "no-space, alpha-numeric"
			},
			input3: {
				field: "value",
				label: "FB Value",
				placeholder: "0.00",
				error: "This field should contain a dollar amount (including cents).",
				info: "Optional - insert the value of the transaction you'd like to pass to Facebook.",
				example: "",
				validator: "no-space, currency",
				autoInput: "0.00",
			}
		},
		message: {
			heading: "",
			copy: "<i class='fa fa-exclamation-circle' style='color: #777; margin-right: 3px;'></i> Be sure you delete any existing Facebook pixels from Everflow Postbacks.",
		},
		resources: {
			heading: "Copy/paste the script below onto your presell pages to ensure FBCLID passes through to GiddyUp.",
			link: '<script type="text/javascript" src="https://js.giddyup.io/gulinkfixup.js"></script>',
			copy: "Note: this code should work on most of your hosted pages but GiddyUp cannot provide support if it doesn’t work – this is something a 3rd party developer will need to assist with."
		}
	},
	{
		name: "Facebook Pixel",
		brand: "Facebook",
		config: "facebook-disabled",
		icon: "Facebook_Logo.svg",
		body: "Facebook pixels cannot be configured on Shared Domains. Please reach out to your GiddyUp Account Manager to gain access to Exclusive Domains.",
	},
	{
		name: "Snapchat Pixel",
		brand: "Snapchat",
		config: "snapchat",
		icon: "SnapChat_Logo.svg",
		info: "The Snapchat pixel contains letters and number separated by dashes in this format: <span class='no-break'>xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.</span>",
		action: "update",
		form: {
			input1: {
				field: "pixelId",
				label: "Snapchat Pixel",
				placeholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
				error: "This field should only contain letters and numbers separated by dashes. Double check the formatting.",
				info: "",
				validator: "no-space, alpha-numeric-delimited, custom-format"
			}
		},
		message: {
			heading: "",
			copy: "<i class='fa fa-exclamation-circle' style='color: #777; margin-right: 3px;'></i> Be sure you delete any existing Snapchat pixels from Everflow Postbacks.",
		},
	},
	{
		name: "TikTok Pixel",
		brand: "TikTok",
		config: "tiktok",
		icon: "TikTok_Logo.svg",
		info: "Enter your pixel information below to setup tracking with advanced matching and maximize your conversion accuracy! Make sure you follow <a href='https://giddyup.thinkific.com/courses/take/everflow-101/lessons/27359046-installing-tiktok-pixels-in-everflow' target='_blank'>these instructions</a> to take advantage of this feature.",
		info2: "The TikTok pixel is a single string containing only letters and numbers.",
		action: "update",
		form: {
			input1: {
				field: "pixelId",
				label: "TikTok Pixel",
				placeholder: "xxxxxxxxxxxxxx",
				error: "This field should only should contain only letters and numbers.",
				info: "",
				validator: "no-space, alpha-numeric"
			},
			input2: {
				field: "value",
				label: "TikTok Value",
				placeholder: "0.00",
				error: "This field should contain a dollar amount (including cents).",
				info: "Optional - insert the value of the transaction you'd like to pass to TikTok.",
				example: "",
				validator: "no-space, currency",
				autoInput: "0.00",
			}
		},
		message: {
			heading: "",
			copy: "<i class='fa fa-exclamation-circle' style='color: #777; margin-right: 3px;'></i> Be sure you delete any existing TikTok pixels from Everflow Postbacks.",
		},
		resources: {
			heading: "Copy/paste the script below onto your presell pages to ensure TTCLID passes through to GiddyUp.",
			link: '<script type="text/javascript" src="https://js.giddyup.io/gulinkfixup.js"></script>',
			copy: "Note: this code should work on most of your hosted pages but GiddyUp cannot provide support if it doesn’t work – this is something a 3rd party developer will need to assist with."
		}
	},
	{
		name: "Other Ad Platforms",
		config: "other",
		icon: "",
		body: "Additional ad platforms are coming soon! Please use <a href='https://giddyup.everflowclient.io/offers/postbacks?tab=conversions' target='_blank' rel='noreferrer'>Postbacks</a> in Everflow to fire all other pixels and let your GiddyUp Account Manager know which ad platform you'd like added here.",
	}
]