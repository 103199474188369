export function extractQueryParams(urlString) {
  const url = new URL(urlString);
  const keys = url.searchParams.keys();
  const params = {};

  for (const key of keys) {
    params[key] = url.searchParams.get(key);
  }

  return params;
}
