/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Button, Form, Row, Col} from "react-bootstrap";
import {postDnsRecord} from "../../../apis/updateData";
import PropTypes from "prop-types";
import {UserContext} from "../../../context/user";

const VerifyDomain = ({
  data,
  withDomain,
  withOfferId,
  withPixel = "",
  closeModal,
  configureDomain,
  waitIndicator,
  isNew,
  //refreshData,
  rootDomain
}) => {

  // Location token
  let {token} = useParams();

  // Encrypted token
  const {state: user} = React.useContext(UserContext);
  const encryptedToken = token || user.token || (new URLSearchParams(window.location.search)).get('efo_mp');

  // Working domain
  const [workingDomain, setWorkingDomain] = useState("");

  // API Response
  const [apiResponseMsg, setApiResponseMsg] = useState("");

  // Process steps
  const [thisStep, setThisStep] = useState(0);
  const totalSteps = 5;

  // Step-based advancement
  const [wait4, setWait4] = useState(true);
  const [wait5, setWait5] = useState(true);

  // Hint flags
  const [tip4, setTip4] = useState(false);

  // Input handling
  const [inputs, setInput] = useState({});
  const handleUpdate = (input) => {
    setInput({...inputs, [input.target.id]: input.target.value});
  }

  function getBaseDomain(hostname) {
    const parts = hostname.split('.');
    if (parts.length <= 2) {
      return hostname; // This is already a base domain
    }

    const newParts = parts.slice(1);

    // Join the elements of the new array with a period
    const result = newParts.join('.');

    // Return all but the first part
    return result;
  }

  // Content display
  const DisplayHeader = (step) => {
    switch (step) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return (
          <div className="modal-title h4">Facebook Domain Verification</div>
        );
    }
  }
  const DisplayContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="description">
            <p>In order to use Exclusive Domains with Facebook, you need to verify your domain using the <strong>Facebook Business Manager</strong>.</p>
            <p>{isNew && (
                <>
                  YOU ONLY NEED TO DO THIS ONCE FOR <strong>{getBaseDomain(workingDomain)}</strong>.{' '}
                  YOU DO NOT NEED TO DO THIS FOR EACH SUBDOMAIN.
                </>
            )}</p>
            <p>Let&apos;s get started&hellip;</p>
          </div>
        );
      case 1:
        return (
          <div className="description">
            <ul>
              <li>
                Open the Facebook Ad Manager in a new tab.
                <Button
                  variant="secondary"
                  onClick={onOpenFB}
                  size="sm"
                >
                  Click to Open Ad Manager
                </Button>
              </li>
              <li>Once you log in, go to <strong>Brand Safety &rarr; Domains</strong>.</li>
            </ul>
            <div className="screenshots">
              <div className="capture">
                <img src="./images/screenshots/step1-1.jpg"/>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="description">
            <ul>
              <li>
                Click on <strong>Add</strong> to display the <strong>Add a Domain</strong> box.
              </li>
            </ul>
            <div className="screenshots">
              <div className="capture">
                <img src="./images/screenshots/step2-1.jpg"/>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="description">
            <ul>
              <li>
                Copy the Exclusive Domain URL into the field and click <strong>Add Domain</strong>.
              </li>
              <li>
                Click to copy the URL:
                <Button
                  variant="secondary"
                  size="sm"
                  value={getBaseDomain(workingDomain)}
                  onClick={(e) => {
                    onCopyText(e.target.value);
                  }}
                >
                  {getBaseDomain(workingDomain)}
                </Button>
              </li>
            </ul>
            <div className="screenshots">
              <div className="capture">
                <img src="./images/screenshots/step2-2.jpg"/>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <>
            <div className="description">
              <ul>
                <li>Select <strong>Update the DNS TXT record with your domain registrar</strong> in the drop down.</li>
                <li>
                  Paste the <strong>DNS TXT Record</strong> below.
                  <br/>
                  <small>(It&apos;s the entire string, starting with &quot;facebook-domain-verification=&quot;)</small>
                </li>
              </ul>
              <div className="screenshots">
                <div className="capture">
                  <img src="./images/screenshots/step3-1.jpg"/>
                </div>
              </div>
              {tip4 &&
              <div className="error-tips">
                <strong>Input Tips:</strong>
                <p>Copy and paste the exact verification code from Facebook.</p>
                <p>Code should begin with: &quot;facebook-domain-verification=&quot; and end with a string of letters and numbers.</p>
                <p>If the error persists, you may try again from the Facebook panel available from the &quot;Configure Pixels&quot; button.</p>
              </div>
              }
            </div>
            <Form
              className="dns-form"
              data-form-status={formStatus}
            >
              <div className="form-fields">
                <Row className="g-2">
                  <Col xs="10">
                    <Form.Control
                      id="txtRecord"
                      name="txtRecord"
                      type="text"
                      placeholder="DNS TXT Record"
                      defaultValue=""
                      value={inputs.txtRecord}
                      onChange={(e) => handleUpdate(e)}
                    />
                    <Form.Text className={error["txtRecord"] ? "validation error" : "validation"}>{error["txtRecord"]}</Form.Text>
                  </Col>
                  <Col xs="2" className="no-padding-left">
                    <Button
                      className="full-width"
                      variant="secondary"
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="processing status-message">
                <i className="fa fa-spinner fa-spin"></i>Saving the DNS record...
              </div>
              <div className="success status-message">
                <i className="fa fa-check"></i><span className="message">{apiResponseMsg}</span>
              </div>
              <div className="failure status-message">
                <i className="fa fa-exclamation-triangle"></i><span className="message">{apiResponseMsg}</span> <span className="try-again" onClick={onDnsRetry}>Please try again.</span>
              </div>
            </Form>
          </>
        );
      case 5:
        return (
          <>
            <div className="description">
              <ul>
                <li>Click <strong>Verify</strong> in Facebook and confirm you can verify your domain.</li>
                <li>
                  Did your domain pass verification?
                </li>
              </ul>
            </div>
            <Form>
              <Button
                variant="secondary"
                onClick={onUnverified}
              >
                No
              </Button>
              &nbsp; &nbsp;
              <Button
                variant="secondary"
                onClick={(e) => setWait5(false)}
              >
                Yes
              </Button>
            </Form>
          </>
        );
    }
  }
  const DisplayFooter = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Button
              variant="link"
              onClick={onConfigure}
            >
              Back
            </Button>
            <Button
              variant="action"
              onClick={onAdvance}
            >
              Start Verification
            </Button>
          </>
        );
      case 1:
      case 2:
        return (
          <>
            <Button
              variant="link"
              onClick={onRegress}
            >
              Back
            </Button>
            <Button
              variant="action"
              //disabled={wait1}
              onClick={onAdvance}
            >
              Next
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <Button
              variant="link"
              onClick={onRegress}
            >
              Back
            </Button>
            <Button
              variant="action"
              //disabled={wait3}
              onClick={onAdvance}
            >
              Next
            </Button>
          </>
        );
      case 4:
        return (
          <>
            <Button
              variant="link"
              onClick={onRegress}
            >
              Back
            </Button>
            <Button
              variant="action"
              //disabled={wait4}
              onClick={onAdvance}
            >
              Next
            </Button>
          </>
        );
      case 5:
        return (
          <>
            <Button
              variant="link"
              onClick={onRegress}
            >
              Back
            </Button>
            <Button
              variant="action"
              disabled={wait5}
              onClick={onVerified}
            >
              Finish Verification
            </Button>
          </>
        );
    }
  }


  // Form submit / validation
  const [error, setError] = useState({});
  const [formStatus, setFormStatus] = useState("ready");
  const onSubmit = () => {
    // hide tips
    setTip4(false);
    // Special validation with format fixing
    if (Object.keys(inputs).length) {
      let errors = {};
      let isValid = true;
      let field = inputs["txtRecord"];
      if (field !== undefined && field !== "") {
        // quick format check
        if (~field.indexOf('=')) {
          const strArray = field.split('=');
          // validate: check if split[1] contains a space
          if (!(strArray[1].trim()).match(/^\S*$/) && isValid) {
            isValid = false;
            errors["txtRecord"] = 'Please remove any blank spaces to continue';
          }
          // validate: check if split[1] contains only lowercase alphas
          if (((strArray[1].trim()).toLowerCase() != (strArray[1].trim())) && isValid) {
            isValid = false;
            errors["txtRecord"] = 'Please enter lowercase letters only.';
          }
          // validate: check if split[1] is alpha-numeric
          if (!(strArray[1].trim()).match(/^[a-zA-Z0-9]+$/i) && isValid) {
            isValid = false;
            errors["txtRecord"] = 'Only letters and numbers may follow "facebook-domain-verification=".';
          }
          // force preamble to expected value
          field = "facebook-domain-verification=" + strArray[1];
        } else {
          isValid = false;
          errors["txtRecord"] = 'The input should begin with "facebook-domain-verification=".';
        }
      } else {
        isValid = false;
        errors["txtRecord"] = "This field can not be empty.";
      }
      if (isValid) {
        setInput({...inputs, txtRecord: field});
        setFormStatus('processing');
        updateDnsRecord(field);
      } else {
        setError(errors);
        console.error("Form has Errors \r\n", error);
      }
    }else{
      setError({txtRecord: "This field can not be empty."});
      console.error("Form has Errors \r\n", error);
    }
  }

  // Update DNS record
  const updateDnsRecord = (data) => {
    const payload = {
      domainName: workingDomain,
      txtRecord: data,
      isNew: isNew
    };

    console.log('POST:', encryptedToken, payload);
    postDnsRecord(encryptedToken, payload).then(data => {
        if(data.error){
          if(data.error == 400 && ~data.message.indexOf("already exists")){
            // success
            console.log('Allowing user to continue without save.');
            setFormStatus('success');
            setApiResponseMsg("Your DNS record already saved.");
            setWait4(false);
          }else{
            // failed with response
            console.error(data.error);
            setApiResponseMsg(data.message);
            setFormStatus("failure");
          }
        }else{
          if (data) {
            // success
            setFormStatus('success');
            setApiResponseMsg("Your DNS record saved successfully.");
            setWait4(false);
          } else {
            // failed without response
            setApiResponseMsg("There was a problem saving your DNS record.");
            console.error('Error saving DNS record');
            setFormStatus("failure");
          }
        }
    });
  }

  // Actions
  const onReset = () => {
    setThisStep(0);
    setWait4(true);
    setWait5(true);
    setTip4(false);
    setInput({});
    setError({});
    setFormStatus("ready");
  }
  const onAdvance = () => {
    setThisStep(thisStep + 1);
  }
  const onRegress = () => {
    setThisStep(thisStep - 1);
  }
  const onOpenFB = () => {
    const link = "https://www.facebook.com/ads/manage";
    window.open(link, '_blank');
  }
  const onConfigure = () => {
    configureDomain(withPixel, withOfferId, false, false);
  }
  const onDnsRetry = () => {
    setTip4(true);
    setWait4(true);
    setFormStatus("ready");
  }
  const onUnverified = () => {
    onDnsRetry();
    setThisStep(4);
  }
  const onVerified = () => {
    configureDomain("facebook", withOfferId, false, true);
  }
  const onCopyText = (value) => {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    console.log('Copied to clipboard', value);
  };

  // Effects
  useEffect(() => {
    onReset();
    if (withDomain) {
      console.log('Verification for:', withOfferId, withDomain);
      setWorkingDomain(withDomain);
    }
  }, []);
  useEffect(() => {
  }, [inputs]);

  return (
    <>
      <div className="modal-header custom">
        {DisplayHeader(thisStep)}
      </div>
      <div className="content custom" data-step={thisStep}>
        {DisplayContent(thisStep)}
      </div>
      <div className="modal-footer custom">
        {thisStep > 0 &&
          <div className="footnote step-display">Step {thisStep} of {totalSteps}</div>
        }
        {DisplayFooter(thisStep)}
      </div>
    </>
  );
};

VerifyDomain.propTypes = {
  data: PropTypes.object,
  withDomain: PropTypes.string,
  withOfferId: PropTypes.string,
  withPixel: PropTypes.string,
  closeModal: PropTypes.func,
  configureDomain: PropTypes.func,
  waitIndicator: PropTypes.func,
  refreshData: PropTypes.func,
  isNew: PropTypes.bool,
  rootDomain: PropTypes.string,
};

export default VerifyDomain;
