import React, {useState} from "react";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const QrCode = ({ urlString, className }) => {

  // modal handling
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleOnQrCopy = () => {
    const img = document.createElement('img');
    img.src = document.getElementById('qrcode').toDataURL();
    const el = document.createElement('div');
    el.contentEditable = true;
    el.appendChild(img);
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    if (document.body.createTextRange) {
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    let copied = document.execCommand('copy');
    document.body.removeChild(el);
    console.log('Image copied to clipboard', copied);
    setShow(false)
  };


  return (
    <>

      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Get QR code</Tooltip>}
      >
        <div className={className}>
          <svg
            onClick={handleOpen}
            className="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 20 20"
            height="18px"
            viewBox="0 0 20 20"
            width="18px"
            fill="#000000"
          >
            <g>
              <rect fill="none" height="20" width="20" x="0" />
            </g>
            <g>
              <g>
                <path d="M4,9h5V4H4V9z M4.94,4.94h3.12v3.12H4.94V4.94z" />
                <path d="M4,16h5v-5H4V16z M4.94,11.94h3.12v3.12H4.94V11.94z" />
                <path d="M11,4v5h5V4H11z M15.06,8.06h-3.12V4.94h3.12V8.06z" />
                <rect height="1" width="1" x="15" y="15" />
                <rect height="1" width="1" x="15" y="13" />
                <rect height="1" width="1" x="15" y="11" />
                <rect height="1" width="1" x="12" y="12" />
                <rect height="1" width="1" x="11" y="11" />
                <rect height="1" width="1" x="13" y="13" />
                <rect height="1" width="1" x="14" y="14" />
                <rect height="1" width="1" x="13" y="11" />
                <rect height="1" width="1" x="14" y="12" />
                <rect height="1" width="1" x="11" y="13" />
                <rect height="1" width="1" x="12" y="14" />
                <rect height="1" width="1" x="11" y="15" />
                <rect height="1" width="1" x="13" y="15" />
              </g>
            </g>
          </svg>
        </div>
      </OverlayTrigger>

      <Modal
        size="sm"
        className="modal-qr-code"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          <QRCode
            id="qrcode"
            value={urlString}
            size={150}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="action" onClick={handleOnQrCopy}>
            Copy image
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

QrCode.propTypes = {
  urlString: PropTypes.string,
  className: PropTypes.string,
};

export default QrCode;
