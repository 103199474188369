import axios from "axios";

console.log('process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);

const MARKETING_PARTNER_BASE_URL = (
    (process.env.REACT_APP_ENV === "development") ? "https://dev.gu-api.com/marketing-partners/" :
    (process.env.REACT_APP_ENV === "qa") ? "https://qa.gu-api.com/marketing-partners/" :
    (process.env.REACT_APP_ENV === "production") ? "https://gu-api.com/marketing-partners/" : ''
);

export default axios.create({
  baseURL: MARKETING_PARTNER_BASE_URL,
});
