import React, { useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CopyLink = ({ urlString, className }) => {
  const [message, setMessage] = useState("Copy link");

  const handleOnCopyClick = (link) => {
    const el = document.createElement('textarea');
    el.value = link;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    console.log('Link copied to clipboard', link);
    setMessage("Copied");
    setTimeout(function () {
      setMessage("Copy link");
    }, 3000);
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{message}</Tooltip>}
    >
      <div className={className}>
        <svg
          onClick={() => handleOnCopyClick(urlString)}
          xmlns="http://www.w3.org/2000/svg"
          height="18px"
          viewBox="0 0 24 24"
          width="18px"
          fill="#000000"
          className="transform scale-75 cursor-pointer"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
        </svg>
      </div>
    </OverlayTrigger>
  );
};

CopyLink.propTypes = {
  urlString: PropTypes.string,
  className: PropTypes.string,
};

export default CopyLink;
