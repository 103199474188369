/* eslint-disable no-unused-vars */
import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";

const TestPurchase = ({
  withURL,
  withLabel,
}) => {

  // Modal handling
  const [showModal, setShowModal] = useState(false);
  const onCloseModal = () => setShowModal(false);
  const onOpenModal = () => setShowModal(true);

  // Test Link
  const TestLink = () => {
    const link = new URL(withURL);
    link.searchParams.set('gutestpurchase', 1);
    return link.toString();
  };

  // Actions
  const onTest = () => {
    window.open(TestLink(), '_blank');
    onCloseModal();
  }
  const onCopyText = (value) => {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    console.log('Copied to clipboard', value);
  };


  return (
    <>
      <Button
        variant="link"
        type="button"
        className={withURL ? "test-btn" : "hidden"}
        onClick={onOpenModal}
        value={withURL}
      >
        {withLabel}
      </Button>
      <Modal
        className="modal-test"
        show={showModal}
        onHide={onCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Fire a Test Purchase Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Click the <strong>Fire Test Purchase</strong> button below to fire a purchase event across all ad platforms.</p>
          <p>Note: The Purchase Event will ONLY fire on Offer Pages (ie. /offer-01).</p>
          <p>If you would also like to manually test inside Facebook Ad Manager, click the URL below to copy the test link:</p>
          <div className="resources">
            <p className="link" onClick={(e) => onCopyText(TestLink())}>
              <span><TestLink/></span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={onCloseModal}>
            Close
          </Button>
          <Button variant="action" onClick={onTest}>
            Fire Test Purchase
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TestPurchase.propTypes = {
  withURL: PropTypes.string,
  withLabel: PropTypes.string,
};

export default TestPurchase;
