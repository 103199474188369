import React from "react";
import {
    BrowserRouter as Router,
    Route }
from "react-router-dom";

import PixelsAndDomains from "./pages/PixelsAndDomains";
import { UserProvider } from "./context/user";

// Location token
function App() {

  return (
    <UserProvider>
      <Router>
          <Route
              exact
              path={`/:token`}
              component={PixelsAndDomains}
          />
      </Router>
    </UserProvider>
  );
}

export default App;
