export const header = `
  
    <h1>Premium Pixels & Exclusive Domains</h1>
    <p>
    Take advantage of GiddyUp's proprietary solution that provides Marketing Partners with the most accurate and advanced pixel firing available – stop worrying about tracking issues and focus on what you do best!
    </p>
    <p>
    UPDATE: Oct 2024<br/>
    Revamped Exclusive Domains are now live! Take advantage of faster page load speeds, automated image optimization, support for Advertorial, VSL, & Listicle domains. Plus with direct linking you can now avoid destination URL mismatches.
    </p>
    <p>
    <a target="_blank" href="https://helpcenter.giddyup.io/en/collections/10701434-premium-pixels-exclusive-domains">Learn more about the changes here.</a>
    </p>
    <p>
    To gain access to the new Exclusive Domains, please contact your GiddyUp Account Manager! All legacy Exclusive Domains will continue to work until sometime in Q1 2025.
    </p>
`;