/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { UserContext } from "../../../context/user";
import { platforms } from "./platforms"
import { putDomainPixel } from "../../../apis/updateData";

const PixelConfig = ({
  withDomain,
  withDomainType,
  withDomainData,
  withPixel,
  withVerification,
  withBrandName,
  withOfferId,
  configureDomain,
  waitIndicator,
  showNotification,
  closeModal,
  refreshData,
  isNew
}) => {

  // Location token
  let {token} = useParams();

  // Encrypted token
  const {state: user} = React.useContext(UserContext);
  const encryptedToken = token || user.token || (new URLSearchParams(window.location.search)).get('efo_mp');

  // Offer & brand settings
  const [offerId, setOfferId] = useState("");
  const [brandName, setBrandName] = useState("");

  // Get configuration properties for ad platform
  const [pixel, setPixel] = useState("");
  const [platform, setPlatform] = useState({});
  const getPlatform = () => {
    if(pixel !== ""){
      platforms.filter(function (e) {
        if (e.config === pixel) {
          setPlatform(e);
        }
      });
    }else{
      setPlatform({});
    }
  }

  // UI handling
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  // Input handling
  const [inputs, setInput] = useState({});
  const [initialValue, setInitialValue] = useState({});
  const handleUpdate = (input) => {
    if((input.target.value).trim() !== ""){
      setInput({...inputs, [input.target.id]: input.target.value});
    }
  }

  // Populate inputs
  const populateInputs = () => {
    if (withDomainData !== undefined) {
      if (withDomainData.pixelConfigs !== undefined) {
        const configData = Object.keys(withDomainData.pixelConfigs).length > 0 ? withDomainData.pixelConfigs[pixel] : {};
        if (platform.form && configData) {
          if (Object.keys(configData).length > 0) {
            setInput(configData);
            setInitialValue(configData);
          }
        }
      }
    }
  }

  // Platform dropdown list
  const [selectedPixel, setSelectedPixel] = useState("facebook");
  const SelectPlatformOptions = () => {
    const Content = [];
    // Pixel actions to display
    let actions = {};
    platforms.forEach(function (platform) {
      if (platform.action === "update") {
        actions[platform.brand] = platform.config;
      }
    });
    Content.push(
      <Form.Control
        key="0"
        id="platform-select"
        name="platform-select"
        as="select"
        custom={true}
        defaultValue="facebook"
        onChange={(e) => setSelectedPixel(e.target.value)}
      >
        {Object.keys(actions).map(function (key, index) {
          return <option key={index} value={actions[key]}>{key}</option>;
        })}
        <option value="other">Other Ad Platforms</option>
      </Form.Control>
    );
    return Content;
  }

  // Content display
  const DisplayHeader = (platform) => {
    if(platform.config === undefined){
      return (
        <div className="modal-title h4">Choose Your Primary Ad Platform</div>
      );
    }else{
      const ConfigForm = FormBuilder(false);
      return (
        <div
          className="modal-title h4"
          style = {platform.icon ? {
            backgroundImage: `url("./images/${platform.icon}")`,
            backgroundPosition: "left center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            paddingLeft: `${ConfigForm.displayForm ? "50px" : "40px"}`,
          } : {}}
        >
          {platform.name}
          {ConfigForm.displayForm &&
            <div className="subtitle">Domain: {withDomain}</div>
          }
        </div>
      );
    }
  }
  const DisplayContent = (platform) => {
    if(platform.config === undefined){
      return (
        <>
          <div className="description">
            <p>Select the primary ad platform you will be running traffic on with this domain.</p>
          </div>
          <Form>
            {SelectPlatformOptions()}
          </Form>
        </>
      );
    }else{
      const ConfigForm = FormBuilder();
      return (
        <>

          {ConfigForm.displayForm && platform.info &&
            <p className="info" dangerouslySetInnerHTML={{__html: platform.info}} />
          }
          {ConfigForm.displayForm && platform.info2 &&
            <p className="info" dangerouslySetInnerHTML={{__html: platform.info2}} />
          }

          {platform.body &&
            <p className="body" dangerouslySetInnerHTML={{__html: platform.body}} />
          }

          {ConfigForm.displayForm
            ?
              <Form id="configure-platform" className={"platform-" + platform.config}>
                {ConfigForm.content}
              </Form>
            :
              <>{ConfigForm.content}</>
          }

          {platform.resources &&
            <div className="resources">
              {platform.resources.heading &&
                <strong className="heading">{platform.resources.heading}</strong>
              }
              {platform.resources.link &&
              <p
                className="link"
                onClick={(e) => onCopyText(platform.resources.link)}>
                <span>{platform.resources.link}</span>
              </p>
              }
              {platform.resources.copy &&
                <p className="body" dangerouslySetInnerHTML={{__html: platform.resources.copy}} />
              }
            </div>
          }

          {platform.message &&
            <div className="message">
              {platform.message.heading &&
                <strong className="heading">{platform.message.heading}</strong>
              }
              {platform.message.copy &&
                <p className="body" dangerouslySetInnerHTML={{__html: platform.message.copy}} />
              }
            </div>
          }
        </>
      );
    }
  }
  const DisplayFooter = (platform) => {
    if(platform.config === undefined){
      return (
        <>
          <Button
            variant="action"
            onClick={onConfigure}
          >
            Next
          </Button>
        </>
      );
    }else{
      const ConfigForm = FormBuilder(false);
      return (
        <>
          {(platform.config === "facebook" && !withVerification) &&
            <div className="footnote">
              Still need to verify your domain?<Button variant="link" onClick={onVerify}>Click here.</Button>
            </div>
          }
          {ConfigForm.displayForm && platform.form
            ?
            <>
              {withPixel === "" &&
                <Button variant="link" onClick={onRegress}>Back</Button>
              }
              <Button variant="action" onClick={submitForm} disabled={disableButton}>OK</Button>
            </>
            :
            <>
              {withPixel === "" &&
                <Button variant="link" onClick={onRegress}>Back</Button>
              }
              <Button variant="action" onClick={closeModal}>OK</Button>
            </>
          }
        </>
      );
    };
  };

  // Build form
  const FormBuilder = (renderForm = true) => {
    let displayForm = true;
    const Content = [];
    if (platform.action === "update") {

      if(renderForm){
        if (pixel === "google") {
          let inputField1 = platform.form.input1;
          let inputField2 = platform.form.input2;
          Content.push(
            <div key="0">
              <label>
                {inputField1.label}
                {inputField1.example &&
                <a className="example" href={inputField1.example} target="_blank" rel="noreferrer">(Example)</a>
                }
              </label>
              <div className="input-container">
                <div className="input-group" data-validator={inputField1.validator}>
                  <Form.Control
                    id={inputField1.field}
                    name={inputField1.field}
                    type="text"
                    //value={inputs[inputField1.field]}
                    placeholder={inputField1.placeholder}
                    defaultValue={inputs[inputField1.field]}
                    onChange={(e) => handleUpdate(e)}
                  />

                </div>
                <div className="input-group" data-validator={inputField1.validator}>
                  <Form.Control
                    id={inputField2.field}
                    name={inputField2.field}
                    type="text"
                    //value={inputs[inputField2.field]}
                    placeholder={inputField2.placeholder}
                    defaultValue={inputs[inputField2.field]}
                    onChange={(e) => handleUpdate(e)}
                  />

                </div>
              </div>
              <div className={error[inputField1.field] || error[inputField2.field] ? "validation-container invalid" : "validation-container"}>
                <Form.Text className={error[inputField1.field] ? "field-1 validation error" : "field-1 validation"}>
                  {error[inputField1.field]}
                </Form.Text>
                <Form.Text className={error[inputField2.field] ? "field-2 validation error" : "field-2 validation"}>
                  {error[inputField2.field]}
                </Form.Text>
              </div>
            </div>
          );
        } else {
          Object.keys(platform.form).forEach(function (key, index) {
            let inputField = platform.form["input" + (index + 1)];
            Content.push(
              <div key={index}>
                <label>
                  {inputField.label}
                  {inputField.example &&
                  <a className="example" href={inputField.example} target="_blank" rel="noreferrer">(Example)</a>
                  }
                </label>
                <div className="input-container">
                  <div className="input-group" data-validator={inputField.validator}>
                    {inputField.validator.includes('currency') &&
                      <span className={"leading-indicator"}>$</span>
                    }
                    <Form.Control
                      id={inputField.field}
                      name={inputField.field}
                      type="text"
                      //value={inputs[inputField.field]}
                      placeholder={inputField.placeholder}
                      defaultValue={inputs[inputField.field]}
                      onChange={(e) => handleUpdate(e)}
                    />
                    {inputField.info &&
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`info-tooltip-${index}`}>
                          {inputField.info}
                        </Tooltip>
                      }
                    >
                      <Button className="infodot">i</Button>
                    </OverlayTrigger>
                    }
                  </div>
                </div>
                <div className={error[inputField.field] ? "validation-container invalid" : "validation-container"}>
                  <Form.Text className={error[inputField.field] ? "validation error" : "field-0 validation"}>
                    {error[inputField.field]}
                  </Form.Text>
                </div>
              </div>
            );
          });
        }
      }

      displayForm = true;

    } else if (platform.action === "clear") {

      // Build removal inputs
      if (withDomainData.pixelConfigs !== undefined) {
        Object.keys(withDomainData.pixelConfigs).forEach(function (item, index) {
          if (withDomainData.pixelConfigs[item] !== null) {
            Content.push(
              <div key={index}>
                <Form.Check
                  type="checkbox"
                  id={item}
                  name={item}
                  label={platform.form.checkboxes[item]}
                  onChange={(e) => handleUpdate(e)}
                />
              </div>
            );
          }
        });
      }

      if (Content.length > 0) {
        displayForm = true;
      } else {
        Content.push(
          <div>{platform.error}</div>
        );
        displayForm = false;
      }

    } else {
      displayForm = false;
    }
    return {
      content: Content,
      displayForm: displayForm
    };
  }

  // Form validation
  const [error, setError] = useState({});
  const validate = () => {
    const validators = [];
    let errors = {};
    let isValid = true;
    let thread;

    // get fields
    Object.keys(platform.form).forEach(function (input, index) {
      const field = platform.form[input].field;
      thread = inputs[field];

      let format = '';
      const validation = (platform.form[input].validator).split(',');
      validation.forEach(function (validator, index) {

        switch(validator.trim()){
          case 'no-space':
            format = /^\S*$/;
            break;
          case 'lowercase-only':
            format = /^[a-z0-9]+$/i;
            break;
          case 'numeric':
            format = /^[0-9]+$/;
            break;
          case 'alpha-numeric':
            format = /^[a-zA-Z0-9]+$/i;
            break;
          case 'alpha-numeric-delimited': // assumed with dash
            format = /^[a-zA-Z0-9-]+$/;
            break;
          case 'currency':
            format = /^\d+(?:\.\d{0,2})$/;
            break;
          case 'custom-format':
            switch(platform.config){
              case 'snapchat':
                format = /^\(?([a-zA-Z0-9]{8})\)?[-. ]?([a-zA-Z0-9]{4})[-. ]?([a-zA-Z0-9]{4})[-. ]?([a-zA-Z0-9]{4})[-. ]?([a-zA-Z0-9]{12})$/;
                break;
              default:
                format = '';
            }
            break;
          default:
            format = '';
        }

        // apply validation
        if (thread) {
          if(format !== ''){
            if (!(thread.trim()).match(format)) {
              isValid = false;
              validators.push(validator);
              if(validator === 'no-space'){
                errors[field] = "Please remove any blank spaces to continue.";
              }else{
                errors[field] = platform.form[input].error;
              }
            }
          }
        } else {
          // Auto-input
          if (platform.form[input].autoInput) {
            const value = platform.form[input].autoInput;
            setInput({...inputs, [field]: value});
          }else{
            isValid = false;
            validators.push('not-empty');
            errors[field] = "This field can not be empty.";
          }
        }

      });
    });
    if(validators.length > 0){
      console.error('Validator errors:', validators);
    }
    setError(errors);
    return isValid;
  }

  // Form submission
  const [disableButton, setDisableButton] = useState(true);
  const submitForm = () => {
    if(platform.action === "update"){
      if (validate()) {
        waitIndicator("Saving...");
        setError({});
        setInput({});
        updateDomainPixel();
      } else {
        console.error("Form has Errors \r\n", error);
      }
    }else if (platform.action === "clear") {
      waitIndicator("Updating...");
      setError({});
      setInput({});
      removeDomainPixels();
    }
  }

  // Update pixel data
  const updateDomainPixel = () => {
    const payload = {
      pixelConfigs: {
        [platform.config]: {}
      }
    };
    if(withDomainType === "shared"){
      payload.brandName = "DEFAULT";
      payload.exclusiveDomain = withDomain;
    }else{
      payload.brandName = brandName;
    }
    payload.isNew = isNew;

    Object.keys(inputs).forEach(function (field, index) {
      // preformat awId
      if(field === "awId"){
        if(~inputs[field].indexOf('-')){
          const strArray = (inputs[field]).split('-');
          if(strArray.length > 1){
            payload.pixelConfigs[platform.config][field] = "AW-" + strArray[1];
          }else{
            payload.pixelConfigs[platform.config][field] = inputs[field];
          }
        }else{
          payload.pixelConfigs[platform.config][field] = "AW-" + inputs[field];
        }
      }else{
        payload.pixelConfigs[platform.config][field] = inputs[field];
      }
    });

    console.log('* PUT:', offerId, payload);
    /* caution: pixel configs can be written even if domain hasn't been created */
    putDomainPixel(encryptedToken, offerId, payload).then(data => {
      if (data) {
        // success
        refreshData();
        setTimeout(function(){
          closeModal();
          showNotification('success', '<strong>Success: </strong> the pixel configuration was saved.');
       	}, 4000);
      } else {
        // failed without response
        closeModal();
        showNotification('danger', '<strong>Error: </strong> updating the pixel configuration failed. Please try again.');
      }
    });
  }
  const removeDomainPixels = () => {
    const payload = {
      pixelConfigs: {}
    };
    if (withDomainType === "shared") {
      payload.brandName = "DEFAULT";
      payload.exclusiveDomain = withDomain;
    } else {
      payload.brandName = brandName;
    }
    Object.keys(inputs).forEach(function (field, index) {
      payload.pixelConfigs[field] = null;
    });
    console.log('* PUT:', offerId, payload);
    /* caution: pixel configs can be written even if domain hasn't been created */
    putDomainPixel(encryptedToken, offerId, payload).then(data => {
      if (data) {
        // success
        refreshData();
        setTimeout(function(){
          closeModal();
          showNotification('success', '<strong>Success: </strong> the pixel configuration was removed.');
       	}, 4000);
      } else {
        // failed without response
        closeModal();
        showNotification('danger', '<strong>Error: </strong> updating the pixel configuration failed. Please try again.');
      }
    });
  }

  // Actions
  const onConfigure = () => {
    if(selectedPixel === "facebook"){
      configureDomain("facebook", offerId, true);
    }else{
      setPixel(selectedPixel);
    }
  }
  const onRegress = () => {
    setSelectedPixel("facebook");
    setPixel("");
  }
  const onVerify = () => {
    configureDomain("facebook", offerId, true);
  }
  const onDismissDisclaimer = () => {
    setShowDisclaimer(false);
  }
  const onCopyText = (value) => {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    console.log('Copied to clipboard', value);
  };

  // Effects
  useEffect(() => {
    if(withPixel !== ""){
      setPixel(withPixel);
    }

    if(withOfferId){
      setOfferId(withOfferId);
    }else if(withDomainData) {
      setOfferId(withDomainData.offerId || withDomainData.networkOfferId);
    }

    if(withBrandName){
      setBrandName(withBrandName);
    }else if(withDomainData){
      setBrandName(withDomainData.brandName);
    }

    let showDisclaimerNow = false;
    if(withDomain.indexOf('fixd.io') > -1 || withDomain.indexOf('fixd-') > -1){
      showDisclaimerNow = true;
      if(withDomainType === "shared" && (withPixel === "facebook-disabled" || withPixel === "other")){
        showDisclaimerNow = false;
      }
    }
    setShowDisclaimer(showDisclaimerNow);

  }, []);

  useEffect(() => {
    getPlatform(pixel);
  }, [pixel]);

  useEffect(() => {
    populateInputs();
  }, [platform]);

  useEffect(() => {
    if (Object.keys(inputs).length === 0) { // no value
      setDisableButton(true);
    }else if(inputs === initialValue){ // initial value, no update
      setDisableButton(true);
    }else{
      setDisableButton(false);
    }
  }, [inputs]);

  return (
    <>
      <div className="modal-header custom">
        {DisplayHeader(platform)}
      </div>
      {showDisclaimer
      ?
        <>
          <div className="content custom disclaimer">
            <p>
              <strong className="header">Important Notice for FIXD</strong>
            </p>
            <p>
              Premium Pixels are not compatible with the Carthook variations on FIXD (they are labeled as “CH -” when choosing a destination URL), so you must use <a
              href='https://giddyup.everflowclient.io/offers/postbacks?tab=conversions' target='_blank'
              rel="noreferrer">Postbacks</a> in Everflow to fire your tracking pixels.
            </p>
            <p>
              For all other non-Carthook traffic, simply click &ldquo;Continue&rdquo; to add your pixels.
            </p>
          </div>
          <div className="modal-footer custom disclaimer">
            <Button
              variant="action"
              onClick={onDismissDisclaimer}
            >
              Continue
            </Button>
          </div>
        </>
      :
        <>
          <div className="content custom">
            {DisplayContent(platform)}
          </div>
          <div className="modal-footer custom">
            {DisplayFooter(platform)}
          </div>
        </>
      }
    </>
  );
};

PixelConfig.propTypes = {
  withPixel: PropTypes.string,
  withDomain: PropTypes.string,
  withDomainType: PropTypes.string,
  withDomainData: PropTypes.object,
  withBrandName: PropTypes.string,
  withOfferId: PropTypes.string,
  withVerification: PropTypes.bool,
  configureDomain: PropTypes.func,
  closeModal: PropTypes.func,
  waitIndicator: PropTypes.func,
  showNotification: PropTypes.func,
  refreshData: PropTypes.func,
  isNew: PropTypes.bool,
};

export default PixelConfig;
