
// Poll Utility
export const poll = async ({ fn, validate, interval, maxAttempts, exponential = false, backoffRate = 1 }) => {
  let attempts = 0;

  const CustomError = function (msg) {
    this.message = msg;
  };

  // eslint-disable-next-line consistent-return
  const executePoll = async (resolve, reject) => {
    const result = await fn();
    attempts++;

    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new CustomError('Polling exceeded max attempts'));
    }

    const backOffInterval = interval * (backoffRate ** attempts);
    setTimeout(executePoll, exponential ? backOffInterval : interval, resolve, reject);
  };

  return new Promise(executePoll);
};