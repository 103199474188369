import axios from "axios";

console.log('process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);

const DJANGO_BASE_URL = (
    (process.env.REACT_APP_ENV === "development") ? "https://staging.gu-plat.com/api/" :
    (process.env.REACT_APP_ENV === "qa") ? "https://staging.gu-plat.com/api/" :
    (process.env.REACT_APP_ENV === "production") ? "https://partner.giddyup.io/api/" : ''
);

export default axios.create({
    baseURL: DJANGO_BASE_URL,
});
