import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { extractQueryParams } from "../libs/url-lib";

const UserContext = React.createContext();

const user = {
  token: "",
  id: "",
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_TOKEN":
      return { ...state, token: action.payload };
    default:
      return state;
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(userReducer, user);

  useEffect(() => {
    const queryParams = extractQueryParams(window.location.href);
    dispatch({ type: "UPDATE_TOKEN", payload: queryParams?.efo_mp || "" });
  }, []);

  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.element,
};

export { UserContext, UserProvider };
