/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import {Alert, Button} from "react-bootstrap";
import DomainListPanels from "../components/DomainListPanels";
import TrackingLinkPanel from "../components/TrackingLinkPanel";
import marketingPartnerApi from "../apis/mp-configs"
import useApi from "../hooks/useApi";
import {modelExDomainData, modelRunnableOfferData, modelSharedOfferData} from "../apis/modelData";
import {UserContext} from "../context/user";
import {header} from "./header";

const PixelsAndDomains = () => {

  // Location token
  let { token } = useParams();

  // Encrypted token
  const {state: user} = React.useContext(UserContext);
  const encryptedToken = token || user.token || (new URLSearchParams(window.location.search)).get('efo_mp');

  // Loading timer
  const [loaded, setLoaded] = useState(false);
  const [retry, setRetry] = useState(false);
  const loadingTimer = () => {
    setRetry(false);

    const interval = 30000;
    setTimeout(function () {
      setRetry(true);
      //console.error(`Could not get data after ${interval * 0.001} seconds.`);
    }, interval);
  }

  // Notifications
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [alertStyle, setAlertStyle] = useState(false);
  const Notifications = () => {
    if (showAlert) {
      setTimeout(function () {
        setShowAlert(false);
      }, 7000);
      return (
        <Alert
          variant={alertStyle}
          onClose={() => setShowAlert(false)}
          dismissible={true}
        >
          <p dangerouslySetInnerHTML={{__html: alertMsg}}/>
        </Alert>
      );
    } else {
      return null;
    }
  }
  const onShowNotification = (style = "info", message) => {
    setShowAlert(true);
    setAlertMsg(message);
    setAlertStyle(style); // style: danger, success, info
  }

  /* Api Hooks */
  // All offers (non-affiliate): customDomainPrefix, everflowOfferId, offer, offerType
  const {
    loading: offersListLoading,
    data: offersListData,
    request: getOffersList,
    error: offersListError,
  } = useApi(marketingPartnerApi.getOffersList);
  // By affiliate runnable offers: name, networkOfferId, offerStatus, trackingUrl
  const {
    loading: mpRunnableOffersLoading,
    data: mpRunnableOffersData,
    request: mpRunnableOffers,
    error: mpRunnableOffersError,
  } = useApi(marketingPartnerApi.getMarketingPartnerRunnableOffers);
  // By affiliate: all offer config data
  const {
    loading: mpOfferConfigsLoading,
    data: mpOfferConfigsData,
    request: mpOfferConfigs, // exclusive domains
    error: mpOfferConfigsError,
  } = useApi(marketingPartnerApi.getMarketingPartnerConfigs);
  // By affiliate: affiliateId, brandName
  const {
    loading: mpBrandsLoading,
    data: mpBrandsData,
    request: mpGetBrands, // brands
    error: mpBrandsError,
  } = useApi(marketingPartnerApi.getMarketingPartnerBrands);
  // By affiliate:
  const {
    loading: mpSharedOffersLoading,
    data: mpSharedOffersData,
    request: mpGetSharedOffers, // shared offers
    error: mpSharedOffersError,
  } = useApi(marketingPartnerApi.getMarketingPartnerSharedOffers);

  // Data handling
  const [dataRefreshTrigger, setRefreshTrigger] = useState("");
  const [exDomains, setExDomains] = useState(null);
  const [sharedDomains, setSharedDomains] = useState(null);
  const [offerPrefixes, setOfferPrefixes] = useState(null);
  const [runOffers, setRunOffers] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [mpBrands, setMpBrands] = useState(null);
  const data = {
    mpBrands: mpBrands,
    exDomains: exDomains,
    sharedDomains: sharedDomains,
    offerPrefixes: offerPrefixes,
    offerData: offerData,
    runOffers: runOffers,
  };

  // Actions
  const refreshData = () => {
    const str = new Date().getTime();
    setRefreshTrigger(str);
  }
  const onRetry = () => {
    window.location.reload();
  }

  // Effects
  useEffect(() => {
    loadingTimer();
  }, []);
  useEffect(() => {
    console.info('loading data');
    mpGetBrands(encryptedToken);
    mpOfferConfigs(encryptedToken);
    getOffersList();
    mpRunnableOffers(encryptedToken, {page: 1, pageSize: 1000});
    mpGetSharedOffers(encryptedToken);
  }, [dataRefreshTrigger]);
  useEffect(() => {
    if (Object.keys(mpOfferConfigsData).length && Object.keys(mpBrandsData).length) {
      const offerData = modelExDomainData(mpOfferConfigsData, mpBrandsData.result, true);
      setExDomains(offerData.collated);
      setOfferData(offerData.native);
      setMpBrands(mpBrandsData.result);
    }
  }, [mpOfferConfigsData, mpBrandsData]);
  useEffect(() => {
    if (Object.keys(mpRunnableOffersData).length && Object.keys(offersListData).length) {
      setRunOffers(mpRunnableOffersData.result.offers);
      setOfferPrefixes(modelRunnableOfferData(mpRunnableOffersData.result.offers, offersListData.result.offers));
    }
  }, [mpRunnableOffersData, offersListData]);
  useEffect(() => {
    if (Object.keys(mpOfferConfigsData).length && Object.keys(mpSharedOffersData).length) {
      setSharedDomains(modelSharedOfferData(mpSharedOffersData.result.offers, mpOfferConfigsData.result));
    }
  }, [mpOfferConfigsData, mpSharedOffersData]);
  useEffect(() => {
    if (exDomains !== null && runOffers !== null && sharedDomains !== null ){
      setLoaded(true);
    }
  }, [exDomains, runOffers, sharedDomains]);

  if (loaded) {
    return (
      <>
        <Notifications/>
        <div className="container-fluid dashboard">
          <div className="row dashboard-header">
            <div
              className="col-xs-12 col-sm-8 col-md-8"
              dangerouslySetInnerHTML={{__html: header}}
            />
            <div className="col-xs-12 col-sm-4 col-md-4">
              <a href="https://helpcenter.giddyup.io/en/collections/10701434-premium-pixels-exclusive-domains" target="_blank" rel="noreferrer" className="help-link">?</a>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8 col-md-8">
              <DomainListPanels
                showNotification={onShowNotification}
                refreshData={refreshData}
                data={data}
              />
            </div>
            <div className="col-sm-4 col-md-4">
              <TrackingLinkPanel
                data={data}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    if(encryptedToken){
      return(
        <>
          {retry
            ? <div className="loading-retry">
                <p><i className="fa fa-exclamation-triangle"/> Could not load the app.</p>
                <Button variant="action" onClick={onRetry}>Retry?</Button>
              </div>
            : <div className="loading"><div className="spinner" /></div>
          }
        </>
      );
    }else{
      return(<div className="token-error">Token Error</div>);
    }
  }
};

export default PixelsAndDomains;
